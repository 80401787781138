.wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--primary);
    z-index: 99;

    &.search {
      border-radius: 0pt;
    }
}

.wrapper[data-section="nav"],
.wrapper[data-section="menu"] {
    border-radius: 12pt 12pt 0pt 0pt;
}


.nav[data-is-fixed="true"] {
    padding: 1rem;
    background: var(--primary);
}

.content{
  flex: 1;
  overflow: auto;

  &.search {
    height: calc(var(--vh, 100dvh) - 66px);
  }

  &.menuProfile {
    height: calc(var(--vh, 100dvh) - 66px);
  }

  .wrapper[data-section="rewards"] &,
  .wrapper[data-section="campaigns"] & {
    height: calc(var(--vh, 100dvh) - 66px);
    padding-right: 0.75rem;
    padding-bottom: 0.75rem;

    &::-webkit-scrollbar {
      width: 3px;
      height: 0px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.8);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--secondary);
    }
  }
}

.filter-input {
    background: #fff;
    border-radius: 20pt;
    color: var(--primary);

    input {
        border-radius: unset;
        background: #fff;
        border: none;
        color: var(--primary);
        width: 100%;
        height: 20px;
        line-height: 20px;
        font-size: 20px;
        padding: 0px;
        padding-bottom: 5px;
    }
}
.noScroll {
    overflow: hidden;
}
